body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f1f6fa;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.boardAndNumPadContainer {
}

.sidebar {
  width: 200px;
}

.userIcon {
  width: 4.5vmax;
  margin: 1.5vmax .75vmax 1.5vmax 1.5vmax;
  display:inline-block;
  transform: rotate(-6deg);
}

.userIconFaceLeft {
  transform: rotate(6deg) scaleX(-1.0);
}

.userIconFaceRight.userIconDancing {
  animation-name: iconDancingAnimation;
  animation-duration: 1.3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.userIconFaceLeft.userIconDancing {
  animation-name: iconFaceLeftDancingAnimation;
  animation-duration: 1.3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.userBar {
  background-color: #c9e7ff;
  display: flex;
  justify-content: center;
  align-items: center;
}


@keyframes iconDancingAnimation {
  0%   {
    transform: rotate(15deg);
  }
  50% {
    transform: rotate(-15deg);
  }
  100% {
    transform: rotate(15deg);
  }
}

@keyframes iconFaceLeftDancingAnimation {
  0%   {
    transform: rotate(-15deg) scaleX(-1.0);
  }
  50% {
    transform: rotate(15deg) scaleX(-1.0);
  }
  100% {
    transform: rotate(-15deg) scaleX(-1.0);
  }
}

.highlightOpacity {
  opacity: .4;
}

.cellTableData {
  position: relative;
  width: 5vmax;
  height: 5vmax;
}

.multiCell {
  font-size: 1.3vmax;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 0px 0px;
  width: 100%;
  height: 100%;
}

.cellBackground {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

table.sudoku {
  border: .5vmax solid #000000;
  border-collapse: collapse;
  margin: 1vmax auto 1vmax auto;
  font-size: 3.25vmax;
}


@media (min-aspect-ratio: 9/10) {
  table.sudoku {
    margin: 2vmax 1vmax 1vmax auto;
  }

  .userIcon {
    width: 2.5vmax;
    margin: 1vmax .5vmax 1vmax 1vmax;
  }
  
  .boardAndNumPadContainer {
    display: flex;
    align-items: center;
  }
}


@media (min-aspect-ratio: 14/10) {
  
  table.sudoku {
    font-size: 2vmax;
  }
  .cellTableData {
    width: 4.6vmax;
    height: 4.6vmax;
  }
  
  .multiCell {
    font-size: 1vmax;
  }
}

@media (min-aspect-ratio: 17/10) {
  .cellTableData {
    width: 3.5vmax;
    height: 3.5vmax;
  }
  .multiCell {
    font-size: .7vmax;
  }
}

@media (max-aspect-ratio: 6/10) {
  table.sudoku {
    /* margin: 1vmax auto 1vmax auto; */
    font-size: 2vmax;
  }

  .cellTableData {
    width: 4vmax;
    height: 4vmax;
  }

  .multiCell {
    font-size: 1vmax;
  }
}

@media (max-aspect-ratio: 4/10) {
  .cellTableData {
    width: 3vmax;
    height: 3vmax;
  }
  .multiCell {
    font-size: .7vmax;
  }
}

table.minisudoku {
  border: 2px solid #334443;
  border-collapse: collapse;
  background-color: #fff;
  color: black;
}

tbody.minisudoku {
  cursor: pointer;
}

td.minisudoku {
  height: 10px;
  width: 10px;
  border: 1px solid #CCC;
  text-align: center;
  font-size: xx-small;
  outline: none;
}

td {
  border: .2vh solid #CCC;
  text-align: center;
  outline: none;
}

td:nth-child(3n) {
  border-right: .3vh solid #000000;
}

tr:nth-child(3n) {
  border-bottom: .3vh solid #000000;
}

td:nth-child(3n).minisudoku {
  border-right: 2px solid #152726;
}

tr:nth-child(3n).minisudoku {
  border-bottom: 2px solid #152726;
}

.selected {
  background-color: #b0fdad;
}

.background-green {
  background-color: #8fff8b;
}

.background-lavender {
  background-color: #cac4ff;
}

.background-sky-blue {
  background-color: #add9fd;
}

.background-brown {
  background-color: #ccab7b;
}

.background-tan {
  background-color: #fddaad;
}

.background-pink {
  background-color: #fdadf6;
}

.background-purple {
  background-color: #db95fc;
}

.background-sea-blue {
  background-color: #76a4fa;
}

.background-red {
  background-color: #fa8181;
}

.background-yellow {
  background-color: #ffe928;
}

.user-icon-green {
  color: #8fff8b;
}

.user-icon-lavender {
  color: #cac4ff;
}

.user-icon-sky-blue {
  color: #add9fd;
}

.user-icon-brown {
  color: #ccab7b;
}

.user-icon-tan {
  color: #fddaad;
}

.user-icon-pink {
  color: #fdadf6;
}

.user-icon-purple {
  color: #db95fc;
}

.user-icon-sea-blue {
  color: #76a4fa;
}

.user-icon-red {
  color: #fa8181;
}

.user-icon-yellow {
  color: #ffe928;
}

.same-number-highlight {
  background-color: #5afc68;
}