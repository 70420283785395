.num-pad-grid-container {
  /* transform: scaleX(.8) scaleY(.8); */
  margin: 0px auto;
  padding: 1.5vmax;
  width: 33vmax;
  border-radius: 1vmax;
  background-color: #c5ced6;
  display: grid;
  grid-template-columns: 6vmax 6vmax 6vmax 12vmax;
  grid-template-rows: 6vmax 6vmax 6vmax;
  gap: 1vmax 1vmax;
  justify-content: center;
  align-items: center;
  grid-template-areas:
    "one two three delete"
    "four five six multi"
    "seven eight nine undo-redo";
}

.num-pad-button {
    cursor: pointer;
    font-size: 4vmax;
    /* font-weight: bold; */
  border-radius: 1.2vmax;
  /* padding: 1px; */
  height: 100%;
  width: 100%;
  background-color: #f1f6fa;
  display: inline-grid;
  justify-content: center;
  align-items: center;
  /* box-shadow: 1px 1px #b8b8b8; */
  transition: all .14s ease;
  /* position: absolute; */
  /* top: 90%; */
  /* margin: auto; */
}

.eraser {
    height: 4.5vmax;
    width: 4.5vmax;
    /* font-weight: bolder; */
    /* stroke-width: .1pt; */
    /* color: #c5ced6; */
}

.pencil {
    height: 4.5vmax;
    width: 4.5vmax;
    /* font-weight: bolder; */
    stroke-width: .1pt;
    /* color: #c5ced6; */
}

.undo {
    height: 2.7vmax;
    width: 2.7vmax;
    /* font-weight: bolder; */
    /* stroke-width: .1pt; */
    /* color: #c5ced6; */
}

.redo {
    height: 2.7vmax;
    width: 2.7vmax;
    /* font-weight: bolder; */
    /* stroke-width: .1pt; */
    /* color: #c5ced6; */
}

.num-pad-one {
  grid-area: one;
}

.num-pad-two {
  grid-area: two;
}

.num-pad-three {
  grid-area: three;
}

.num-pad-four {
  grid-area: four;
}

.num-pad-five {
  grid-area: five;
}

.num-pad-six {
  grid-area: six;
}

.num-pad-seven {
  grid-area: seven;
}

.num-pad-eight {
  grid-area: eight;
}

.num-pad-nine {
  grid-area: nine;
}

.num-pad-delete {
  grid-area: delete;
  font-size: medium;
  font-weight: normal;
  /* padding-top: 10px;
  padding-bottom: 10px; */
  /* width: 60px; */
}

.num-pad-undo-redo {
    grid-area: undo-redo;
    display: grid;
    grid-template-columns: 5.7vmax 5.7vmax;
    grid-template-rows: 6vmax;
    gap: .7vmax .7vmax;
    justify-content: center;
  align-items: center;
    /* padding-top: 10px;
    padding-bottom: 10px; */
    /* width: 60px; */
  }

  .num-pad-redo {
    /* padding-top: 10px;
    padding-bottom: 10px; */
    /* width: 60px; */
  }

  .num-pad-undo {
    /* padding-top: 10px;
    padding-bottom: 10px; */
    /* width: 60px; */
  }

.num-pad-multi {
    grid-area: multi;
    font-size: medium;
    font-weight: normal;
    /* padding-top: 10px;
    padding-bottom: 10px; */
    /* width: 60px; */
  }

.button-pressed {
    background-color: #4b4b4b;
    color: #c5ced6;
    transition: all .14s ease;
/* padding-top: 10px;
padding-bottom: 10px; */
/* width: 60px; */
}