.homePage {
    /* background-color: #c9e7ff; */
    background-color: #f1f6fa;
    /* color: #f2fdff; */
    color: #3a5d92;
    /* height: 100%; */
    min-height: 100vh;
  }
  
  .home-topbar-grid-container {
    display: grid;
    grid-template-columns: 60px 300px 300px 1fr;
    grid-template-rows: 60px;
    gap: 0px 0px;
    background-color: #88b7ff;
    grid-template-areas: "topbar-logo topbar-time . .";
  }
  
.home-topbar-logo {
    margin-left: 45px;
    margin-top: 10px;
    margin-bottom: 10px;
    grid-area: topbar-logo;
  }
  
  .home-topbar-logo-text {
    margin: auto;
    font-family: "Nunito", sans-serif;
    font-size: 35px;
    color: #3a5d92;
    /* color: #f1f6fa; */
    /* color: #88b7ff */
    /* f9f7ef */
  }

  a.home-topbar-logo-text{
    text-decoration: none;
  }
  
  /* .topbar-grid-container {
      background-color: #f1f6fa;
  } */
  
  .miniboardsContainer {
    display: flex;
    flex-wrap: wrap;
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

.miniboard {
    flex: 50%;
    margin-bottom: 10px;
    /* margin-top: 10px; */
    /* margin-left: 15px; */
  }
  
  @media (min-width: 800px) {
    .miniboard {
      flex: 33%;
    }
  }
  
  .logo-with {
    /* color: #82bbe9; */
    color: #f1f6fa;
    /* color: #caffe2; */
  }
  
  .collapsibleDivisor {
    border-bottom: #3a5d92 1px solid;
    /* margin-top: 10px;
      margin-bottom: 10px; */
  }
  
  .introText {
    margin-top: 20px;
    margin-left: 40px;
    width: 80%;
  }

  .aboutText {
    margin-top: 20px;
    margin-left: 40px;
    margin-bottom: 40px;
    width: 80%;
  }

  .aboutTitle {
    margin-top: 30px;
    margin-left: 40px;
    /* width: 80%; */
    font-size: large;
    /* background-color: #88b7ff; */
  }
  
  .triggerName {
    flex: 50%;
    font-weight: bold;
  }

.triggerIcon {
    flex: 50%;
    text-align: right;
    font-weight: bolder;
  }
  
  .collapsibles {
    margin-top: 20px;
    margin-left: 40px;
    width: 80%;
  }
  
  .collapsibleTrigger {
    /* background-color: #88b7ff; */
    margin: 5px;
    padding: 10px;
    display: flex;
    /* width: 80%; */
    /* width: 50%; */
    /* border-radius: 5px; */
    cursor: pointer;
  }
  
  /* .collapsibleTrigger::hover {
  } */
  
  .collapsibleContent {
    /* background-color: #88b7ff; */
    /* width: 6%; */
    /* width: 80%; */
    /* border-radius: 5px; */
    /* border-left: #3a5d92 1px solid;
      border-right: #3a5d92 1px solid; */
  }