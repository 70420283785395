
.topbar-grid-container {
    display: grid;
    grid-template-columns: 20vw 60vw 20vw;
    grid-template-areas:
        "topbar-logo topbar-time topbar-menu";
    grid-template-rows: 8vmax;
    gap: 0px 0px;
    background-color: #88b7ff;
    align-items: center;
    
  }
  .topbar-logo { 
    /* height: 5vmax; */
    /* width: 3vmax; */
    /* margin-left: 6vmax; */
    display: grid;
    align-items: center;
    /* grid-template-columns: vw 84vw 7vw; */
    /* margin-top: auto;
    margin-bottom: auto; */
    /* margin-top: 1vmax;
    margin-bottom: 1vmax; */
    grid-area: topbar-logo; 
    /* text-align: right; */
}

.topbar-menu {
    grid-area: topbar-menu;
}

.topbar-logo-icon { 
    text-align: right;
    /* margin: auto; */
}

img.topbar-logo-icon { 
    height: 5vmax;
}

.topbar-time { 
    grid-area: topbar-time;
    /* display: grid; */
    margin: 0 auto;
    text-align: center;
    /* margin-right: 3vmax;  */
    /* align-items: center; */
    /* margin-top: 21px;
    margin-left: auto;
    margin-right: auto; */
    /* align-self: center; */
    font-family: "Nunito", sans-serif;
    font-size: 2vmax;
    /* width: 10vmax; */
    color: #3a5d92;
}

.topbar-logo-text {
    margin-left: 1vmax;
    font-family: "Nunito", sans-serif;
    font-size: 4vmax;
    color: #3a5d92;
    display: none;
  }

  a.topbar-logo-text {
    text-decoration: none;
  }

@media (min-aspect-ratio: 9/10) {
    .topbar-grid-container {
        grid-template-columns: 30vw 40vw 30vw;
        grid-template-rows: 7vmax;
        grid-template-areas:
            "topbar-logo topbar-time .";
    }

    img.topbar-logo-icon { 
        height: 4vmax;
    }

    .topbar-time {
        font-size: 1.5vmax;
     }

    .topbar-logo { 
        grid-template-columns: 10vw 20vw;
    }

    .topbar-logo-text {
        font-size: 3vmax;
        display: inline;
    }
  }

  @media (min-aspect-ratio: 14/10) {
    .topbar-grid-container {
        grid-template-columns: 30vw 40vw 30vw;
        grid-template-rows: 5vmax;
        grid-template-areas:
            "topbar-logo topbar-time .";
    }
/* 
    .topbar-time {
        font-size: 1vmax;
     } */

    img.topbar-logo-icon { 
        height: 3vmax;
    }

    .topbar-logo-text {
        font-size: 2vmax;
        display: inline;
    }
  }

/* .topbar {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0px 0px;
    grid-template-areas:
    ". . ."
    ". . .";
    grid-area: 1 / 1 / 2 / 5;
} */

/* .logo {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0px 0px;
    grid-template-areas:
      ". . ."
      ". . .";
    grid-area: 1 / 1 / 2 / 2;
  }

  .topbar-time {
    margin-left: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
    display: grid;
    /* grid-template-columns: 1fr 1fr 1fr; */
    /* grid-template-rows: 1fr 1fr; */
    /* gap: 0px 0px; */
    /* grid-template-areas:
      ". . ."
      ". . ."; */
    /* grid-area: 1 / 1 / 2 / 2;
  } */ 